import Axios from 'axios';
import SyncLoader from "vue-spinner/src/SyncLoader.vue";

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      companies: [],
      users: [],
      userType: 'professionals',
      filter: {
        status: '',
        state: '', // 26
        city: '', // 5034
        country: '', // 3
        name: '',
        razao: '',
        nome_fantasia: '',
        specialities: [],
        specialitiesInput: '',
      },
      states: [],
      cities: [],
      countries: [],
      specialities: [],
      page: 1,
      last_page: 1,
      total: 0,
      sync: true,
      loaderSize: "15px",
      loaderColor: "gray",
      loading: true,
    }
  },

  components: {
    SyncLoader,
  },

  mounted() {
    axios
      .get('/api/v1/users' + this.professionalsQuery, {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.sync = false;
        if (!response.data.error) {
          this.loading = false            
          for (var i = 0; i < response.data.data.length; i++) {
            response.data.data[i].active = false;
            response.data.data[i].feedback = {sent: false,};
          }
          this.users = response.data.data;
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        } else {
          console.log(response.data.error);
        }
    });

    axios
      .get('/api/v1/specialities', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.loading = false
          this.specialities = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });

    setTimeout(() => {
      axios
      .get('/api/v1/companies', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          for (var i = 0; i < response.data.data.length; i++) {
            response.data.data[i].active = false;
          }
          this.companies = response.data.data;
          this.last_page = response.data.last_page;
          this.total = response.data.total;
        } else {
          console.log(response.data.error);
        }
      });
    }, 2000);

    axios
      .get('/api/v1/states', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.loading = false
          this.states = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });

    axios
      .get('/api/v1/cities', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        this.loading = false
        if (!response.data.error) {
          this.cities = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });

    axios
      .get('/api/v1/countries', {
        headers: {
          Authorization: 'Bearer ' + this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          this.loading = false
          this.countries = response.data.data;
        } else {
          console.log(response.data.error);
        }
    });
  },

  computed: {

    csv() {
      let url = '/dashboard/admin/export';
      if (this.userType == 'professionals') {
        url += '?professionals=true';
        if (this.filter.status) {
          url += '&status=' + this.filter.status;
        }
        if (this.filter.country) {
          url += '&country=' + this.filter.country;
          if (this.filter.country == 3 && this.filter.state) {
            url += '&state=' + this.filter.state;
          }
          if (this.filter.country == 3 && this.filter.city) {
            url += '&city=' + this.filter.city;
          }
        }
        if (this.filter.name && this.filter.name.length > 2) {
          url += '&name=' + this.filter.name;
        }
        if (this.filter.speciality && this.filter.speciality.length > 1) {
          url += '&speciality=' + this.filter.speciality;
        }
        if (this.filter.curator && this.filter.curator.length > 1) {
          url += '&curator=' + this.filter.curator;
        }
      } else {
        url += '?clients=true';
        // if (this.filter.country) {
        //   url += '&country=' + this.filter.country;
        //   if (this.filter.country == 3 && this.filter.state) {
        //     url += '&state=' + this.filter.state;
        //   }
        //   if (this.filter.country == 3 && this.filter.city) {
        //     url += '&city=' + this.filter.city;
        //   }
        // }
        if (this.filter.name && this.filter.name.length > 2) {
          url += '&name=' + this.filter.name;
        }
      }
      return url;
    },

    professionalsQuery() {
      let url = '?professionals=true&page=' + this.page;
      if (this.userType == 'professionals') {
        if (this.filter.status) {
          url += '&status=' + this.filter.status;
        }
        if (this.filter.country) {
          url += '&country=' + this.filter.country;
          if (this.filter.country == 3 && this.filter.state) {
            url += '&state=' + this.filter.state;
          }
          if (this.filter.country == 3 && this.filter.city) {
            url += '&city=' + this.filter.city;
          }
        }
        if (this.filter.name) {
          url += '&name=' + this.filter.name;
        }
        if (this.filter.specialities && this.filter.specialities.length > 0) {
          let specialities = this.filter.specialities.map(function(obj){return obj.id});
          specialities = specialities.join(',');
          url += '&specialities=' + specialities;
        }
        if (this.filter.curator && this.filter.curator.length > 1) {
          url += '&curator=' + this.filter.curator;
        }
      }
      return url;
    },

    clientsQuery() {
      let url = '?clients=true&page=' + this.page;
      if (this.userType == 'clients') {
        if (this.filter.country) {
          url += '&country=' + this.filter.country;
          if (this.filter.country == 3 && this.filter.state) {
            url += '&state=' + this.filter.state;
          }
          if (this.filter.country == 3 && this.filter.city) {
            url += '&city=' + this.filter.city;
          }
        }
        if (this.filter.name && this.filter.name.length > 2) {
          url += '&name=' + this.filter.name;
        }
        // if (this.filter.razao && this.filter.razao.length > 2) {
        //   url += '&razao=' + this.filter.razao;
        // }

        // if (this.filter.nome_fantasia && this.filter.nome_fantasia.length > 2) {
        //   url += '&nome_fantasia=' + this.filter.nome_fantasia;
        // }

      }
      console.log(url);

      return url;
    },

    adminsQuery() {
      let url = '?admin=true&page=' + this.page;
      console.log(url);

      return url;
    },

  },

  watch: {

    page() {
      this.users = [];
      this.companies = [];
      switch (this.userType) {
        case 'professionals':
          this.searchProfessionals();
          break;
        case 'clients':
          this.searchClients();
          break;
        case 'admin':
          this.searchAdmins();
          break;
        default:
      }
    }

  },

  methods: {
    approveUser(userId) {
      let data = {
        decision: 1,
      };
      axios
        .put('/api/v1/users/' + userId + '/user-status', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            let index = this.users.map(function(e) { return e.id; }).indexOf(userId);
            if (index > -1) this.users.splice(index, 1);
          } else {
            console.log(response.data.error);
          }
      });
    },

    repproveUser(userId) {
      let data = {
        decision: 3,
      };
      axios
        .put('/api/v1/users/' + userId + '/user-status', data, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            let index = this.users.map(function(e) { return e.id; }).indexOf(userId);
            if (index > -1) this.users.splice(index, 1);
          } else {
            console.log(response.data.error);
          }
      });
    },

    demote(id) {
      let _this = this;
      axios
        .get('/api/v1/users/' + id + '/demote', {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.search();
          } else {
            console.log(response.data.error);
          }
      });

    },

    updateSpecialities(data) {

      let _this = this;
      let post = {
        specialities: data.specialities,
        mainspeciality: data.mainspeciality,
      }

      axios
        .put('/api/v1/users/' + data.id, post, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.errors = response.data.error;
            _this.syncing = false;
          } else {
            _this.syncing = false;
            this.$router.go();
          }
      });
    },

    changeStatusProfile(data) {
      console.log(data)
      let _this = this;
      axios
        .put('/api/v1/users/' + data.user_id, data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (response.data.error) {
            _this.errors = response.data.error;
            _this.syncing = false;
          } else {
            // _this.syncing = false;
            // this.$router.go();
          }
      });
    },

    loginAsUser(userId) {
      axios
        .get('/api/v1/users/' + userId + '/login-as-user', {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            var _this = this;
            // save token for future use
            _this.$store.commit('setToken', response.data.data.access_token);
            // save me for future use
            _this.me = response.data.data.user;
            _this.$store.commit('setMe', response.data.data.user);
            // redirect user to dashboard
            if (_this.me.admin) {
              _this.$router.push({ path: '/dashboard/admin' })
            } else if (_this.me.company_id) {
              _this.$router.push({ path: '/dashboard/client' })
            } else {
              _this.$router.push({ path: '/dashboard/professional' })
            }
          } else {
            console.log(response.data.error);
          }
      });
    },

    sendFeedback(form) {
      let _this = this;
      let formData = new FormData();
      formData.set('comment', form.body);
      formData.set('user', form.user_id);
      axios
        .post('/api/v1/comments', formData, {
          headers: {
            Authorization: 'Bearer ' + this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            const user = _this.users.find( user => user.id ==  form.user_id);
            if (user.feedback) user.feedback.sent = true;
          } else {
            console.log(response.data.error);
          }
      });
    },

    search() { 
      this.loading = true
      switch (this.userType) {
        case 'professionals':
          this.searchProfessionals();
          break;
        case 'clients':
          this.searchClients();
          break;
        case 'admin':
          this.searchAdmins();
          break;
        default:
      }
    },

    searchProfessionals() {
      if (this.userType == 'professionals') {
        this.sync = true;
        axios
          .get('/api/v1/users' + this.professionalsQuery, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            this.sync = false;
            if (!response.data.error) {
              this.loading = false
              for (var i = 0; i < response.data.data.length; i++) {
                response.data.data[i].active = false;
                response.data.data[i].feedback = {sent: false};
              }
              this.users = response.data.data;
              this.last_page = response.data.last_page;
              this.total = response.data.total;
            } else {
              console.log(response.data.error);
            }
        });
      }
    },

    searchClients() {
      if (this.userType == 'clients') {
        this.sync = true;
        axios
          .get('/api/v1/companies' + this.clientsQuery, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            this.sync = false;
            if (!response.data.error) {
              this.loading = false
              for (var i = 0; i < response.data.data.length; i++) {
                response.data.data[i].active = false;
              }
              this.companies = response.data.data;
              this.last_page = response.data.last_page;
              this.total = response.data.total;
            } else {
              console.log(response.data.error);
            }
        });
      }
    },

    searchAdmins() {
      if (this.userType == 'admin') {
        this.sync = true;
        axios
          .get('/api/v1/users' + this.adminsQuery, {
            headers: {
              Authorization: 'Bearer ' + this.TOKEN,
            }
          })
          .then(response => {
            this.sync = false;
            if (!response.data.error) {
              this.loading = false
              for (var i = 0; i < response.data.data.length; i++) {
                response.data.data[i].profile = {};
                response.data.data[i].active = false;
                response.data.data[i].feedback = {sent: false};
              }
              this.users = response.data.data;
              this.last_page = response.data.last_page;
              this.total = response.data.total;
            } else {
              console.log(response.data.error);
            }
        });
      }
    },

    nextPage() {
      this.page++;
    },

    prevPage() {
      this.page--;
    },

    validateList(e) {
      if (this.filter.specialities.length < 5) {
        let text = this.filter.specialitiesInput;
        let index = this.specialities.map(function(e) { return e.pt; }).indexOf(text);
        let double = this.specialities.indexOf(text);
        // faz parte da lista de habilidades
        if (index > -1) {
          // não está ainda na lista selecionada de habilidades
          if (double < 0) this.filter.specialities.push(this.specialities[index]);
        }
      }
      this.filter.specialitiesInput = '';
    },

    removeSpeciality(s) {
      // remove habilidade do briefing
      let index = this.filter.specialities.indexOf(s);
      if (index > -1) {
        this.filter.specialities.splice(index, 1);
        this.searchProfessionals();
      }
    },

  },
}
